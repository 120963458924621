<template>
  <v-container
    id="login"
    class="fill-height"
    tag="section"
  >
    <v-row
      justify="center"
      class="auth"
    >
      <v-slide-y-transition appear>
        <base-material-card
          color="success"
          light
          max-width="100%"
          width="400"
          class="px-5 py-3"
        >
          <template #heading>
            <div class="text-center">
              <h1 class="text-3 ">
                Авторизация
              </h1>
            </div>
          </template>

          <v-form
            ref="loginForm"
            @submit.prevent="authldap"
          >
            <v-card-text
              class="text-center"
            >
              <p>
                Вход как в helpdesk
              </p>
              <v-text-field
                v-model="ldapLogin"
                color="secondary"
                label="Введите имя учетной записи..."
                prepend-icon="mdi-account"
                class="mt-10"
                type="text"
                :rules="ldapRules"
                required
                @click:prepend="clickIn"
              />
              <v-text-field
                ref="passEmp"
                v-model="ldapPass"
                prepend-icon="mdi-lock-outline"
                :type="show1 ? 'text' : 'password'"
                color="secondary"
                label="Пароль..."
                :rules="[rules.required, rules.onlylatin]"
                :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
                required
                @click:append="show1 = !show1"
                class="mt-6"
              />
              <v-text-field
                v-if="godmod"
                ref="host"
                v-model="newHost"
                prepend-icon="mdi-network"
                color="secondary"
                label="backurl..."
                :rules="[rules.onlylatin]"
                class="mt-6"
              />
              <v-select
                v-show="false"
                v-model="domainSelected"
                :items="domain"
                label="Домен:"
                prepend-icon="mdi-server-network"
              />
              <v-checkbox
                v-show="false"
                v-model="rememberMe"
                class="my-2"
                label="Запомнить меня"
              />
              <pages-btn
                type="submit"
                large
                color=""
                :loading="btnDisabled"
                depressed
                class="v-btn--text success--text mt-5"
              >
                Войти
              </pages-btn>
            </v-card-text>
          </v-form>
        </base-material-card>
      </v-slide-y-transition>
    </v-row>
  </v-container>
</template>

<script>
  export default {
    name: 'PagesLogin',

    components: {
      PagesBtn: () => import('./components/Btn'),
    },
    data: () => ({
      clickcount: 0,
      godmod: false,
      btnDisabled: false,
      domainSelected: 'ltd.local',
      domain: [
        'ltd.local',
      ],
      ldapPass: '',
      ldapLogin: '',
      newHost: null,
      rememberMe: true,
      ldapRules: [
        v => !!v || 'Пожалуйста, укажите ваш логин.\n',
      ],
      rules: {
        required: value => !!value || 'Пожалуйста, укажите пароль.',
        onlylatin: value => {
          const regexp = /[а-яА-ЯёЁ]/i
          return !regexp.test(value) || 'Пароль только на латинице.'
        },
      },
      show1: false,
      socials: [
        {
          href: '#',
          icon: 'mdi-facebook-box',
        },
        {
          href: '#',
          icon: 'mdi-twitter',
        },
        {
          href: '#',
          icon: 'mdi-github-box',
        },
      ],
    }),
    computed: {
      lastRoute () {
        return this.$store.getters.lastRoute
      },
    },
    methods: {
      clickIn () {
        if (this.clickcount > 9) {
          this.godmod = true
        }
        this.clickcount++
      },
      authldap () {
        if (this.$refs.loginForm.validate()) {
          const checkLogin = this.ldapLogin.split('@')
          if (checkLogin.length === 2) {
            this.ldapLogin = checkLogin[0]
            if (this.domainSelected.includes(checkLogin[1])) {
              this.domainSelected = checkLogin[1]
            }
          }
          if (this.newHost) {
            this.$store.commit('updateURL', this.newHost)
          }
          this.btnDisabled = true
          this.$store.dispatch('setLoading', true)
          var data = { login: this.ldapLogin, pass: this.ldapPass, domain: this.domainSelected, rememberMe: this.rememberMe }
          this.$store.dispatch('ldap_auth', data).then(() => {
            if (this.lastRoute.fullPath) {
              this.$store.dispatch('setLoading', false)
              this.$router.push(this.lastRoute.fullPath)
              this.btnDisabled = false
            } else {
              this.$router.push('/')
              this.$store.dispatch('setLoading', false)
              this.btnDisabled = false
            }
          }).catch((error) => {
            var errtext = ''
            if (error.response) {
              if ((typeof error.response.data) === 'object') {
                errtext = error.response.data.Message
              } else { errtext = error.response.data }
            } else { errtext = error }
            this.$store.dispatch('setSnackbar', { state: true, text: 'Неправильные учетные данные [ ' + errtext + ' ]', color: 'warning', direction: 'top center' })
            this.$store.dispatch('setLoading', false)
            this.btnDisabled = false
          })
        } else { console.log('not valid') }
      },
    },
  }
</script>

<style>
  .row.auth{
    margin-left: 0;
  }
</style>
